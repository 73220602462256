import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import EstimateForm from './estimate-form'
import CardGeneral from './card-general'
import HeroRatingStars from './hero-rating-stars'
import { monthNames } from '../helpers/all'
import ContactForm from './ContactForm'

const FORMOFFSETPX = 350;

const headerOffsetTransparent = 95;

const ContentContainer = styled.div``

const ContentRow = styled.div``

const ContentCol = styled.div`
  padding-top: 130px;
  padding-bottom: 60px;
  padding-right: 45px;
  color: #fff;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  @media (max-width: 767px) {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 0px;
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
`

const HeroSection = styled.section`
  height: auto;
  background-size: cover;
  border-bottom: 5px solid;
  position: relative;

  .gatsby-image-wrapper {
    &:before {
      content: '';
      position: absolute;
      height: 104%;
      width: 100%;
      background: #000;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0.5;
    }
  }
  .gatsby-image-wrapper [data-main-image] {
    opacity: 1 !important;
    transition: none !important;
  }
`
const HeroHeading = styled.h1`
  position: relative;

  @media (min-width: 767px) {
    font-size: calc(1.35vw + 1.5em) !important;
    text-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px;
    &:before {
      content: '';
      height: 3px;
      width: 50px;
      position: absolute;
      top: -0.5em;
    }
  }
`

const FormWrapper = styled.div`
  margin-top: 2em;
  @media (max-width: 767px) {
    margin-bottom: ${(props) => props.FORMOFFSETPX * -1}px;
  }
`

const HeroSectionOffset = styled.div`
  @media (max-width: 767px) {
    height: ${(props) => props.FORMOFFSETPX - 10}px;
  }
`

const FormHeading = styled.h3`
  padding-bottom: 0px;
  margin-bottom: 5px;
`

const Tag = styled.div`
  display: none;
  background: #ed811f;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  align-items: center;
  color: white;
  text-transform: uppercase;
  padding: 5px;
  min-width: 30px;
  position: relative;
  position: absolute;
  top: -6px;
  right: 21px;
  box-shadow: 0px 2px 5px 1px hsla(0, 0%, 0%, 0.12);
  transform: rotate(10deg);
  &:after {
    content: '';
    position: absolute;
    display: block;
    right: -19px;
    width: 0;
    height: 0;
    top: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 19px solid #ed811f;
  }
  :before {
    content: '';
    background: white;
    height: 8px;
    width: 8px;
    position: absolute;
    right: 0;
    top: calc(50% - 4px);
    border-radius: 50%;
    right: -5px;
    z-index: 100;
  }
  @media (max-width: 480px) {
    &:after {
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
    }
  }
  p {
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 15px;
  }
`
const BadgeItemsContainer = styled.div`
  margin-top: 20px;
  align-items: center;
  flex-wrap: nowrap;
`

const BadgeItem = styled.div`
  flex: 0 0 25%;
  overflow: hidden;
  max-width: 110px;
  padding: 0.5em 0.75em;
  &:last-child {
    padding-right: 0;
  }
`
const BadgeImage = (props) => {
  return (
    <BadgeItem>
      <GatsbyImage
        image={getImage(props.item)}
        objectFit="contain"
        imgStyle={{ objectFit: 'contain' }}
        className="badgeImage"
        alt={props.item.title}
        loading="eager"
      />
    </BadgeItem>
  )
}

// const Badges = (props) => {
//   props.badges.map((item, i) => {
//     console.log("BADGE ITEM")
//     return (
//       <BadgeImage item={item} key={i} />
//     )
//   })
// }

const ServiceHero = ({
  pageData,
  heroImage,
  serviceTitle,
  excerpt,
  specialOffer,
  offerDisclaimer,
  pageProps,
  badges,
  hideForm,
  hideRating,
  SecondaryColumnComponent,
}) => {
  //const FORMOFFSETPX = hideForm || SecondaryColumnComponent ? 0 : 350

  const dateInFiveDays = new Date()
  // deal expires in +5 days
  dateInFiveDays.setDate(dateInFiveDays.getDate() + 5)

  //date day suffix
  const nth = function (d) {
    if (d > 3 && d < 21) return 'th'
    switch (d % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const headerTransparent = false;

  var dealDateStringOutput =
    monthNames[dateInFiveDays.getUTCMonth() % 12] +
    ' ' +
    dateInFiveDays.getUTCDate() +
    nth(dateInFiveDays.getUTCDate())

  return (
    <>

      <div
        className="overflow-hidden"
        style={{
          marginTop: `${headerTransparent ? `${headerOffsetTransparent * -1}px` : "0"
            }`,
        }}
      >
        <div className="h-auto bg-cover relative bg-black ">

          {heroImage && (
            <GatsbyImage
              image={getImage(heroImage)}
              className="hero-image absolute top-0 h-full"

              loading="eager"
            />
          )}


          <div
            className="relative"
            style={{
              background:
                "linear-gradient(0deg, transparent, rgb(2 51 126 / 30%))",
              paddingTop: `${headerTransparent ? `${headerOffsetTransparent}px` : "0"
                }`,
            }}
          >
            <div
              className={`max-w-7xl py-8 carousel-content-container flex flex-wrap justify-between relative mx-auto px-4 md:px-6`}
            >
              <div className="w-full md:w-1/2 lg:w-2/3 py-12 md:py-16 md:pr-8 lg:pr-32 pb-4">
                <div>
                  <h1 className="text-shadow-lg text-center md:text-left text-white text-shadow service-heading-tag relative mb-2 text-3xl lg:text-5xl font-bold leading-10">
                    {serviceTitle}
                  </h1>

                  {excerpt ? (
                    <p className="text-shadow-md text-center md:text-left text-white mt-1 text-base md:text-lg leading-7 py-2">
                      {excerpt}

                    </p>
                  ) : null}

                  {!hideRating && <HeroRatingStars theme="light" classes='text-center md:text-left mb-8' />}
                  {badges ? <div className="flex items-center md:flex-wrap mt-3 w-full space-x-4 ">
                    {badges.map((badge, b) => {
                      return <div className=" p-3 flex items-center" style={{ maxWidth: '110px', height: '50px' }}><GatsbyImage
                        image={getImage(badge)}
                        objectFit="contain"
                        imgStyle={{ objectFit: 'contain' }}
                        alt={badge.title}
                        loading="eager"
                      /></div>
                    })}
                  </div> : null}


                </div>
                {SecondaryColumnComponent && <SecondaryColumnComponent />}
              </div>
              <div
                className="w-full md:w-1/2 lg:w-1/3 form-offset"
              >
                <div className="bg-white rounded-xl p-8 pb-6 border-brand-500 md-mb-none shadow-xl relative mt-6">
                  <div style={{ top: '-12px' }} class="special-tag rounded-r-none absolute bg-orange-500 font-display font-semibold inline-flex items-center mb-2 px-3 py-2 right-0 rounded-full uppercase text-sm text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='text-white w-5 h-5 mr-1'><path fill="currentColor" d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z" /><path fill="currentColor" className="opacity-50" d="M311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93V38.93z" /></svg>
                    <span className='font-display text-xs'>Online Special</span>
                  </div>

                  <div className="mb-4">
                    <h3 className="font-display leading-6 md:leading-7 font-bold text-2xl text-gray-800">
                      {specialOffer
                        ? specialOffer
                        : 'Request ' + serviceTitle + ' Consultation'}
                    </h3>
                    <div className="specialsubheading">{offerDisclaimer}</div>
                    <p className="text-gray-600 mb-2 leading-6 mt-1">
                      Request Free Pricing Today for All Grime Busting Needs!
                    </p>
                  </div>
                  <ContactForm hideLabels={true} showPlaceholders={true} footerContained={true} />

                  {/* <EstimateForm
                      style={'minimal-hero'}
                      showPlaceHolders={true}
                      showLabels={false}
                      pageTitle={serviceTitle}
                      pageProps={pageProps}
                      pageLocation={
                        pageProps.location.href
                          ? pageProps.location.href
                          : 'Not provided'
                      }
                    /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: FORMOFFSETPX + "px" }}
          className="height-none-md"
        />
      </div>

    </>
  )
}

export default ServiceHero
